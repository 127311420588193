export default function About() {
    return (
        <>
            <div className="fixed w-full h-2/3 bg-contain opacity-30 bg-no-repeat left-1/4 z-[-1]"
                 style={{backgroundImage: "url('/Line_gradient_touch_ups_(2).jpeg')"}}></div>

            <div className="pageContainer">
                <div className="flex max-md:flex-col justify-between items-center gap-6 md:gap-10 lg:gap-0">
                    <div className="flex flex-col gap-6">
                        <h1 className="font-bold">about thrombio</h1>
                        <p>
                            ThromBio is a drug discovery company focused on innovative antithrombotic treatments for
                            cardiovascular
                            disease
                            <br/>
                            <br/>
                            ThromBio’s programme is driven by international leaders in drug discovery & development,
                            experimental
                            biomedicine global clinical trials capability and commercialisation
                        </p>
                    </div>
                    <div className="relative flex-grow min-w-[350px] w-full h-full">
                        <img src="/about.png" alt="" className="w-full h-full"/>
                        <div
                            className="absolute top-[56%] left-[36.6%] bg-primary-red rounded-full h-2 w-2 animate-ping"></div>
                    </div>
                </div>
                <div className="flex max-md:flex-col gap-6">
                    <div className="flex flex-col gap-6">
                        <h2 className="font-bold">mission</h2>
                        <h3 className="font-bold">Addressing An Unmet Clinical Need</h3>
                        <p>ThromBio’s mission is to advance the global standard of care for the treatment of stroke
                            using novel anti-thrombotic drugs with unprecedented safety and efficacy</p>
                        <ul className="list-disc pl-6">
                            <li>ThromBio Holdings Pty Ltd stands at a pivotal juncture in the fight against thrombotic
                                disorders
                            </li>
                            <li>The company is spearheading the development of novel therapeutic agents with the
                                potential to significantly advance the treatment of Acute Ischemic Stroke (AIS)
                            </li>
                            <li>ThromBio is leveraging the groundbreaking scientific discoveries emanating from
                                Professors Shaun Jackson’s laboratory
                            </li>
                            <li>ThromBio aims to transform the therapeutic landscape with its lead compounds which offer
                                a unique combination of efficacy and safety
                            </li>
                        </ul>
                        <div className="flex flex-col gap-3">
                            <div className="subheading font-bold">Improving stroke therapy and patient outcomes</div>
                            <ul className="list-disc list-inside">
                                <li>
                                    <span className="font-medium">Market Urgency:</span>
                                    <ul className="list-outside pl-6" style={{listStyleType: "'-'"}}>
                                        <li><div className="ml-4">Stroke remains a leading cause of death and disability worldwide, with limited effective treatments available</div>
                                        </li>
                                        <li><div className="ml-4">ThromBio's innovative approach addresses this substantial unmet need, representing a significant market opportunity</div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="list-disc list-inside">
                                <li>
                                    <span className="font-medium">Innovative New Therapies:</span>
                                    <ul className="list-outside pl-6" style={{listStyleType: "'-'"}}>
                                        <li><div className="ml-4">TBO-309 has a novel mechanism of action, with the potential to extend treatment windows and improve patient outcomes</div>
                                        </li>
                                        <li><div className="ml-4">TBO-309 has shown exceptional promise in enhancing thrombolysis without increasing bleeding risk, potentially redefining acute stroke treatment</div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="list-disc list-inside">
                                <li>
                                    <span className="font-medium">Strategic Development Pathway:</span>
                                    <ul className="list-outside ml-6" style={{listStyleType: "'-'"}}>
                                        <li><div className="ml-4">ThromBio has mapped out a clear and strategic development pathway, from clinical trials to regulatory approval and commercialization.</div>
                                        </li>
                                        <li><div className="ml-4">A major objective for ThromBio is to maximise the therapeutic and commercial potential of its pipeline</div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="list-disc list-inside">
                                <li>
                                    <span className="font-medium">Global Market Potential:</span>
                                    <ul className="list-outside pl-6" style={{listStyleType: "'-'"}}>
                                        <li><div className="ml-4">The global stroke market is expected to experience robust growth in the coming decades</div>
                                        </li>
                                        <li><div className="ml-4">ThromBio's targeted therapies are well-positioned to capture a significant share of the global stroke market, particularly in key markets with high AIS incidence</div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="list-disc list-inside">
                                <li>
                                    <span className="font-medium">Experienced Leadership:</span>
                                    <ul className="list-outside pl-6" style={{listStyleType: "'-'"}}>
                                        <li><div className="ml-4">The company benefits from the strategic guidance of an accomplished Board of Directors, management team and scientific and clinical advisory board, ensuring a focused and effective approach to development and commercialisation</div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
