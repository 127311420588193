import LeadershipProfile from "../components/LeadershipProfile";

export default function Leadership() {
    return (
        <>
            <img src="Line_gradient_touch_ups_(5).jpeg" alt=""
                 className="fixed -right-[22%] top-[10%] lg:top-0 opacity-20 -rotate-[30deg]"/>

            <div className="pageContainer relative">
                <div className="flex flex-col gap-6">
                    <h1 className="font-bold">leadership</h1>
                    <h2>board of directors</h2>
                </div>
                <LeadershipProfile img="/paulHopper.png" position="Chairman" name="Paul Hopper">
                    <p>
                        Paul Hopper has over 25 years of experience in biotech, healthcare and life sciences with a
                        focus on start-up and rapid growth companies. He has served as either Founder, Chairman,
                        non-executive director, or CEO, for more than 15 companies in the US, Australia and Asia.
                    </p>
                </LeadershipProfile>
                <LeadershipProfile img="/davidMazzo.png" position="Board Member" name="David Mazzo">
                    <p>
                        Dr. David J. Mazzo is a highly accomplished and experienced life sciences executive and board
                        director with almost 40 years of experience in managing pharmaceutical and medical device
                        companies. He is recognized for his exceptional strategic, scientific and regulatory
                        expertise, upon which he has amassed a track record of >35 years of successful global product
                        development, registration and launch.
                    </p>
                </LeadershipProfile>
                <LeadershipProfile img="/shaunJackson.png"
                                   position="Founder and Director" name="Professor Shaun Jackson" noBorder>
                    <p>
                        Shaun Jackson is a clinical academic and a National Health and Medical Research Council (NHMRC) of Australia Investigator (Leadership Level 3).
                        He has been
                        a faculty member at the Scripps Research Institute in La Jolla, San Diego (California, USA)
                        since 2009 and an Honorary Visiting fellow at the University of Cambridge, UK. In 2015, he was
                        appointed as the inaugural Director of Cardiovascular Research, at the Heart Research Institute
                        & Charles Perkins Centre, University of Sydney. He has been the founder and director of several
                        Australian biotechnology companies focussing on the clinical development of novel antithrombotic
                        therapeutics.
                    </p>
                </LeadershipProfile>
                <h2>senior management</h2>

                <LeadershipProfile img="/peterBush.png" position="Chief Executive Officer" name="Peter Bush">
                    <p>
                        With over 2 decades of senior leadership and board experience, Peter Bush is an executive,
                        investor,
                        mentor and director, with a focus on the healthcare and technology sectors.
                    </p>
                    <br/>
                    <p>
                        Peter has a proven track record of driving growth, innovation, and commercialization in various
                        public and private entities across Australia, USA, Europe, and Asia. He has extensive expertise
                        in
                        mergers and acquisitions, joint ventures, cross border trade, technology licensing, regulatory
                        compliance and risk management. He is experienced in new business development, venture
                        capital/private equity funding, and technology commercialization.
                    </p>
                    <br/>
                    <p>
                        He holds a Bachelor of Commerce from Macquarie University and a Graduate Diploma in Chartered
                        Accounting from the Institute of Chartered Accountants in Australia.
                    </p>
                </LeadershipProfile>
                <LeadershipProfile img="/rodneyJackson.png" position="Chief Financial Officer" name="Rodney Jackson">
                    <p>
                        Rod is non-executive director on a range of businesses both domestically and internationally.
                        Rod is
                        Chair of Westpac Europe Ltd based in London and Adviser to Executive Global Network in
                        Singapore.
                        Domestically, Rod is a Director of Newcastle Greater Mutual Group, Director of NextSense,
                        Director
                        of Financial Services Institute of Australasia, Director of Searson Buck Group and Chair of the
                        ODEO
                        Group. He has been a director in financial services, recruitment, property management and
                        e-commerce
                        for 15 years.
                    </p>
                    <br/>
                    <p>

                        Rod has worked as an executive in financial services, primarily at Westpac Banking Corporation,
                        for
                        over 40 years. 11 of which have been in Asia and Europe. For the last twenty years Rod has been
                        either a CFO or COO across numerous roles including Westpac Retail and Business Bank and the St
                        George Banking Group. Rod retired from executive life in January 2021 from the CFO, Westpac
                        Institutional Bank role.
                    </p>
                    <br/>
                    <p>

                        Rod holds a Bachelor of Economics (with specialisation in Accounting) from ANU, Canberra,
                        Australia
                        and an MBA from the International Institute for Management Development in Lausanne, Switzerland.
                    </p>
                </LeadershipProfile>
                <LeadershipProfile img="/simoneSchoenwaelder.png"
                                   position="Head, Biology"
                                   name="Simone Schoenwaelder" noBorder>
                    <p>
                        Simone has >25 years experience in medical research, working in the field of cell biology, namely
                        platelet biology and function. After receiving her doctorate from Monash University (Victoria),
                        she completed postdoctoral at the University of North Carolina (Chapel Hill) with Professor
                        Keith Burridge, supported by an NHMRC CJ Martin Fellowship. Her research has been continually
                        funded through competitive grants from the NHMRC, NHF and Department of Health (NSW). She has
                        received numerous awards throughout her career, including a Victorian Premier’s Award for
                        Medical Research Commendation (1997); Young Australian of the Year - State winner, for science
                        and technology (Victoria, 1999) and National Finalist, Young Australian of the Year (1999); City
                        of Whitehorse Young Citizen of the Year (1999); and the THANZ Scientific Medal awarded by the
                        Thrombosis and Haemostasis society of
                        Australia and New Zealand in 2007.
                    </p>
                </LeadershipProfile>
            </div>
        </>
    )
}
