import React, { useRef, useEffect, useState } from "react";

export default function SlideIn(props) {
  const { children } = props;
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(elementRef.current);
        }
      },
      { threshold: 0.2 } // Trigger when 20% of the element is visible
    );

    observer.observe(elementRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={elementRef} className={`slide-in ${isVisible ? "active" : ""}`}>
      {children}
    </div>
  );
}
