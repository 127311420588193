import {useEffect, useState} from "react";

export default function Contact() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [message, setMessage] = useState("");
    const [validEmail, setValidEmail] = useState("");
    const [showErrors, setShowErrors] = useState(false);
    const [somethingWrong, setSomethingWrong] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    function handleSubmit() {
        setSubmitted(true)
        setButtonLoading(true)
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setValidEmail(regex.test(email))
        if (name && regex.test(email) && message) {
            fetch('https://thrombio-api-gateway-2-ccgl0nvc.ts.gateway.dev/publish-sendmail',
            // fetch('https://httpbin.org/post',
                {
                    method: 'POST',
                    headers:
                        {
                            'Accept':
                                'application/json',
                            'Content-Type':
                                'application/json',
                            'x-api-key':
                                'AIzaSyDVN5YSynmvSjrr1Iet95XqMBZQ0kGIKok'
                        }
                    ,
                    body: JSON.stringify({
                        "messageType": "CONTACT_US",
                        name,
                        email,
                        company,
                        message
                    })
                }
            ).then((result) => {
                return result.json()
            }, (error) => {
                setSomethingWrong(true)
                return false
            })
                .then(s => {
                    setSubmitted(true);
                    setButtonLoading(false)
                })
        } else {
            setShowErrors(true)
            setSubmitted(false)
            setButtonLoading(false)
        }
    }

    useEffect(() => {
        if (showErrors) {
            setTimeout(() => {
                setShowErrors(false);
            }, 5000);
        }
    }, [showErrors]);

    return (
        <>
            <div className="fixed w-full h-full bg-contain opacity-20 bg-no-repeat left-1/4 -top-[5%] z-[-1] -rotate-45"
                 style={{backgroundImage: "url('/Line_gradient_touch_ups_(4).jpeg')"}}></div>
            <div className="pageContainer md:max-w-prose">
                <h1 className="font-bold">Contact us</h1>
                <div className="flex flex-col gap-10">
                    <div className="flex flex-col gap-2">
                        <p className="text-gray-700">Name</p>
                        <input onChange={(e) => setName(e.target.value)} readOnly={submitted}
                               className={`text-[1.5rem] border border-gray-300 px-6 py-4 rounded-lg shadow-xs placeholder:text-gray-500 placeholder:opacity-50 ring ring-offset-2 transition duration-500 ${showErrors && !name ? "ring-primary-red" : "ring-transparent"} ${submitted ? "bg-gray-400/50" : ""}`}
                               type="text" placeholder="Enter your name"/>
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className="text-gray-700">Email</p>
                        <input onChange={(e) => setEmail(e.target.value)} readOnly={submitted}
                               className={`text-[1.5rem] border border-gray-300 px-6 py-4 rounded-lg shadow-xs placeholder:text-gray-500 placeholder:opacity-50 ring ring-offset-2 transition duration-500 ${showErrors && !validEmail ? "ring-primary-red" : "ring-transparent"} ${submitted ? "bg-gray-400/50" : ""}`}
                               type="email" placeholder="Enter your email"/>
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className="text-gray-700">Company (optional)</p>
                        <input onChange={(e) => setCompany(e.target.value)} readOnly={submitted}
                               className={`text-[1.5rem] border border-gray-300 px-6 py-4 rounded-lg shadow-xs placeholder:text-gray-500 placeholder:opacity-50 ${submitted ? "bg-gray-400/50" : ""}`}
                               type="text" placeholder="Enter your company"/>
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className="text-gray-700">Message</p>
                        <textarea onChange={(e) => setMessage(e.target.value)} readOnly={submitted}
                                  className={`resize-none text-[1.5rem] border border-gray-300 px-6 py-4 rounded-lg shadow-xs placeholder:text-gray-500 placeholder:opacity-50 ring ring-offset-2 transition duration-500 ${showErrors && !message ? "ring-primary-red" : "ring-transparent"} ${submitted ? "bg-gray-400/50" : ""}`}
                                  placeholder="Enter your message" rows={6}/>
                    </div>
                </div>
                {somethingWrong ?
                    <div className="w-full text-center p-4 bg-red-700 rounded-2xl text-white">Something went wrong,
                        please
                        refresh the page and try again</div>
                    :
                    <button onClick={() => handleSubmit()} disabled={submitted}
                            className={`button flex justify-center items-center normal-case ${showErrors ? "!bg-primary-red !text-white !border-transparent" : submitted ? "bg-primary/80" : "hover:!text-primary"}`}>
                        {buttonLoading ? (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6 animate-spin">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"/>
                            </svg>
                        ) : showErrors ? "Please fill out fields above" : submitted ? "Successfully submitted" : "Submit"}
                    </button>
                }
            </div>
        </>
    )
}
