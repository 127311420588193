import {Link} from "react-router-dom";

export default function Home() {
    return (
        <>
            <div className="flex max-xl:flex-col max-lg:gap-10 px-6 md:px-12 xl:items-center justify-center pb-40"
                 style={{backgroundImage: "url('5dbfaf6e808f9d2271c455b74857743a.png')", backgroundSize: "100% 100%"}}>
                <div className="flex flex-col gap-8">
                    <h1 className="text-white text-[3.2rem] md:text-[4.8rem] lg:text-[6.4rem] font-bold tracking-wide normal-case">ThromBio</h1>
                    <div
                        className="text-white text-[1.8rem] md:text-[2rem] lg:text-[2.4rem] leading-[38.40px] tracking-tight">Innovators
                        in Stroke
                        Therapy
                    </div>
                    <Link to="/technology/candidate"
                          className="button !no-underline !text-white normal-case bg-secondary-navy text-[1.8rem] w-fit hover:!text-primary">Learn
                        more</Link>
                </div>
                <img src="/Thrombio-2.gif" alt="" className=""/>
            </div>
            <div className="pageContainer flex flex-col gap-24">
                <div className="flex max-xl:flex-col-reverse justify-evenly items-center gap-12 lg:gap-40">
                    <div className='flex flex-col gap-12 max-w-prose'>
                        <h2 className="font-bold">About</h2>
                        <p>ThromBio is a drug discovery company whose mission is
                            to advance the global standard of care for the treatment of stroke using novel
                            anti-thrombotic drugs with
                            unprecedented safety and efficacy.</p>
                        <Link to="/about"
                              className="button !no-underline !text-white normal-case bg-secondary-navy text-[1.8rem] w-fit hover:!text-primary">About</Link>
                    </div>
                    <div className="h-[300px] w-[300px] rounded-full" style={{
                        boxShadow: "0 0 10px 10px #000",
                        backgroundColor: "black",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: "url('/1 (1).jpg')"
                    }}>
                    </div>
                </div>
                <div className="flex max-xl:flex-col-reverse xl:flex-row-reverse justify-evenly items-center gap-12 lg:gap-40">
                    <div className='flex flex-col gap-12 max-w-prose'>
                        <h2 className="font-bold">Leadership</h2>
                        <p>ThromBio’s programme is driven by international leaders in drug discovery & development, experimental biomedicine, global clinical trials capability and commercialisation.</p>
                        <Link to="/leadership"
                              className="button !no-underline !text-white normal-case bg-secondary-navy text-[1.8rem] w-fit hover:!text-primary">Leadership</Link>
                    </div>
                    <div className="relative">
                        <img src="/2 (5).png" alt="" className="z-[-1] rounded-full p-2"/>
                        <div className="absolute top-0 left-0 h-full w-full rounded-full border-transparent"
                             style={{
                                 boxShadow: "0 0 28px 28px white inset",
                             }}
                        ></div>
                    </div>
                </div>
                <div className="flex max-xl:flex-col-reverse justify-evenly items-center gap-12 lg:gap-40">
                    <div className='flex flex-col gap-12 max-w-prose'>
                        <h2 className="font-bold">OUR LEAD CANDIDATE: TBO-309</h2>
                        <p>TBO-309 represents an Australian drug development success story, that has resulted in a novel antiplatelet compound with truly remarkable properties.</p>
                        <Link to="/technology/candidate"
                              className="button !no-underline !text-white normal-case bg-secondary-navy text-[1.8rem] w-fit hover:!text-primary">Our Lead Candidate
                            </Link>
                    </div>
                    <div className="relative">
                        <img src="/3 (1).jpg" alt="" className="z-[-1] rounded-full p-2"/>
                        <div className="absolute top-0 left-0 h-full w-full rounded-full border-transparent"
                             style={{
                                 boxShadow: "0 0 28px 28px white inset",
                             }}
                        ></div>
                    </div>
                </div>
                <div className="flex max-xl:flex-col-reverse xl:flex-row-reverse justify-evenly items-center gap-12 lg:gap-40">
                    <div className='flex flex-col gap-12 max-w-prose'>
                        <h2 className="font-bold">LEAD INDICATIONS</h2>
                        <p>Acute Ischaemic stroke remains the second largest cause of death and disability globally,
                            with 85% of all strokes caused by blood clots that block blood flow to the brain.</p>
                        <p>TBO-309 is currently being trialled in patients in Phase II clinical trials, as an adjunct to
                            thrombolysis (STARS).</p>
                        <Link to="/technology/leadIndication"
                              className="button !no-underline !text-white normal-case bg-secondary-navy text-[1.8rem] w-fit hover:!text-primary">Lead Indications</Link>
                    </div>
                    <div className="h-[300px] w-[300px] aspect-square" style={{
                        backgroundPosition: "center",
                        backgroundImage: "url('/starsLogo.jpg')"
                    }}>
                    </div>
                </div>
                <div className="flex max-xl:flex-col-reverse justify-evenly items-center gap-12 lg:gap-40">
                    <div className='flex flex-col gap-12 max-w-prose'>
                        <h2 className="font-bold">publications</h2>
                        <p>ThromBio’s technology is built around state-of-the-art science and technology, published by
                            internationally recognised leaders in the field of drug discovery & and experimental
                            biomedicine.</p>
                        <Link to="/publications"
                              className="button !no-underline !text-white normal-case bg-secondary-navy text-[1.8rem] w-fit hover:!text-primary">Publications</Link>
                    </div>
                    <img src="PR ARTICLE-2.png" alt="" className="rounded-[50px] drop-shadow-md"/>
                </div>
                <div className="flex max-xl:flex-col-reverse xl:flex-row-reverse justify-evenly items-center gap-12 lg:gap-40">
                    <div className='flex flex-col gap-12 max-w-prose'>
                        <h2 className="font-bold">Media</h2>
                        <p>ThromBio’s technology has received global attention for its
                            breakthrough scientific studies.</p>
                        <Link to="/media"
                              className="button !no-underline !text-white normal-case bg-secondary-navy text-[1.8rem] w-fit hover:!text-primary">Media</Link>
                    </div>
                    <div className="relative">
                        <img src="/image%20(1)%20Background%20Removed.png" alt="" className="z-[-1] rounded-full"/>
                        <div className="absolute top-0 left-0 h-full w-full rounded-full border-transparent"
                             style={{
                                 boxShadow: "0 0 48px 48px white inset",
                             }}
                        ></div>
                    </div>
                </div>
            </div>
        </>
    )
}
