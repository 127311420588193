import { useRef } from "react";

export default function Media(props) {
  const mediaCarousel = useRef(null);
  return (
    <>
      <div className="pageContainer grid lg:grid-cols-2 gap-24">
        <img src="/Media_Page_BioSciences_Fund-FINAL_Large.jpeg" alt="BioSciences Fund Poster" className="border border-black"/>
        <img src="/Media_Page_Asia_Bio_Partnering_Forum-FINAL_Large.jpeg" alt="Asia Bio Partnering Forum Poster" className="border border-black"/>
      </div>
      <div className="pageContainer">
        <h1 className="font-bold">Media</h1>
      </div>
      <div className="flex gap-8 xl:page-width mb-40">
        <div className="flex-col justify-center items-center hidden lg:max-xl:flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            onClick={() => mediaCarousel.current.scrollBy({ left: -300, behavior: "smooth" })}
            stroke="currentColor"
            className="w-12 h-12 cursor-pointer"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
          </svg>
        </div>
        <div
          ref={mediaCarousel}
          className="relative flex xl:grid grid-cols-3 max-xl:overflow-x-scroll snap-mandatory snap-x gap-6 md:gap-8 lg:gap-12 xl:gap-x-16 xl:gap-y-8 scrollbar scrollbar-thumb-primary scrollbar-thumb-rounded-full scrollbar-h-2 pb-12 max-lg:px-4"
        >
          <MediaCard
            image="/2023_ABC_News_Breakthrough_anti-clotting_drug_shows_promise_for_treating_strokes.jpeg"
            link="https://www.abc.net.au/news/2023-07-13/breakthrough-anti-clotting-drug-shows-promise-for-strokes/102596222"
          />
          <MediaCard
            image="2023_9News_A_potentially_life-saving_drug_developed_to_treat_strokes.jpeg"
            link="https://www.9news.com.au/national/medical-news-health-stroke-anti-clotting-drug-trials-heart-research-institute/1a6c2caf-b5ad-4c8a-a02f-8d24c162c71c"
          />
          <MediaCard
            image="2023_Herald_Sun_Stroke_eureka_moment.jpeg"
            link="https://www.heraldsun.com.au/news/victoria/royal-melbourne-hospital-among-six-hospitals-to-offer-patients-clotbusting-medication-in-worldfirst-trial/news-story/fbec8154de28ec368ed2b02838276653"
          />
          <MediaCard
            image="2023_3AW_Melbourne_New_clot-busting_stroke_drug_to_be_trialled_in_Australia.jpeg"
            link="https://www.3aw.com.au/new-clot-busting-stroke-medication-to-be-trialled-in-australia/"
          />
          <MediaCard
            image="2021_Daily_Mail_Global_media_interest_in_HRIs_world_first_stroke_drug_trial.jpeg"
            link="https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://www.hri.org.au/news/global-media-interest-in-hris-world-first-stroke-drug-trial&ved=2ahUKEwj8k9Hms5OFAxX_lFYBHQKAD6MQFnoECC4QAQ&usg=AOvVaw2Q2abxPDnJ7zrJVjEU2uWp"
          />
          <MediaCard
            image="2019_HRI_Stroke_scientific_breakthrough.jpeg"
            link="https://www.hri.org.au/news/hri-announces-stroke-scientific-breakthrough"
          />
        </div>
        <div className="flex-col justify-center items-center hidden lg:max-xl:flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            onClick={() => mediaCarousel.current.scrollBy({ left: 300, behavior: "smooth" })}
            stroke="currentColor"
            className="w-12 h-12 cursor-pointer"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        </div>
      </div>
    </>
  );
}

function MediaCard(props) {
  const { image, link } = props;
  return (
    <div className="snap-center lg:snap-start min-w-[350px] shadow-lg hover:shadow-2xl transition-all hover:scale-[100.5%]">
      <a href={link} target="_blank" rel="noreferrer">
        <img src={image} alt="" />
      </a>
    </div>
  );
}
