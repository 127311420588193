import {useState} from "react";

export default function LeadIndications() {
    const [popupOpen, setPopupOpen] = useState(false)
    const [popupImage, setPopupImage] = useState({url: undefined, alt: undefined})
    return (
        <>
            <div className="fixed w-full h-full bg-contain opacity-20 bg-no-repeat z-[-1]"
                 style={{backgroundImage: "url('/Line_gradient_touch_ups_(1).jpeg')"}}></div>

            <div
                className={`${popupOpen ? "opacity-100 translate-x-0" : "translate-x-1/2 opacity-0 z-[-99999]"} ease-out duration-300 transition flex w-dvw h-dvh bg-black/20 fixed top-0 left-0 justify-center items-center`}>
                <div className="max-h-[55dvh] relative">
                    <img src={popupImage.url} alt={popupImage.alt} className="max-h-[55dvh]" loading="lazy"/>
                    <div className="absolute -top-20 right-0">
                        <div onClick={() => setPopupOpen(false)}
                             className="bg-white min-h-16 min-w-16 flex justify-center items-center rounded-full text-[2rem] cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pageContainer">
                <h1 className="font-bold">Lead Indications</h1>
                <div className="flex flex-col gap-6">
                    <h2 className="font-syne text-[3.2rem] font-bold leading-[160%] tracking-[.05rem] uppercase">Acute
                        Ischaemic
                        Stroke</h2>
                    <div>
                        ‘Best practice’ treatment of stroke is a complex paradigm, with an evolving standard of care
                        that is constantly being driven forward with evidence-based research and clinical trials. The
                        societal cost of stroke is large, and the bulk of the economic cost is borne by Federal and
                        State Governments. The annual cost of stroke in Australia was estimated to be $6.2 billion in
                        direct financial costs and a further $26.0 billion in lost wellbeing and premature mortality.
                    </div>
                    <div>
                        With no new approved pharmacological therapy for stroke in 17 years, the demonstration that our
                        drugs are safe and effective would constitute a landmark achievement in the treatment of stroke.
                    </div>
                </div>
                <img src="/leadIndicationStats.png" alt="Lead Indication Stats"/>
                <div className="flex max-xl:flex-col-reverse justify-between items-center">
                    <div>
                        The majority (>85%) of strokes are caused by <span onClick={() => {
                        setPopupImage({url: "/bloodClotFormation.jpeg", alt: "Blood Clot Formation Info"});
                        setPopupOpen(true)
                    }}
                                                                           className="uppercase font-bold text-primary-red underline cursor-pointer">blood clots</span> that
                        block (occlude) blood flow to the
                        brain.
                        <br/><br/>
                        Blood clots form to plug damaged blood vessels. They also form inside diseased blood
                        vessels <span className="italic">(pictured here)</span>, and stop blood flow from reaching vital
                        organs, including the brain – precipitating ischaemic stroke
                    </div>
                    <img src="/leadIndicationsDiagram.png" alt="Lead Indications Diagram" width={375}
                         className="w-[375px]"/>
                </div>
                <div className="flex flex-col gap-6">
                    <h3 className="font-bold text-primary-red text-[2.4rem] leading-[160%] uppercase font-syne">
                        Current standard-of-care therapies
                    </h3>
                    <div>
                        Treatment of stroke requires the rapid removal of occlusive blood clot(s), with the
                        objective to
                        quickly reopen (recanalize) occluded blood vessel(s) and restore blood flow to cerebral
                        tissue
                        (reperfusion), the latter strongly predictive of a favourable outcome.
                    </div>
                    <div>
                        Existing standard-of-care stroke therapies aim to reperfuse ischemic brain tissue with
                        either <span
                        onClick={() => {
                            setPopupImage({url: "/bloodClotRemoval.jpeg", alt: "Blood Clot Removal Info"});
                            setPopupOpen(true)
                        }}
                        className="uppercase font-bold text-primary-red underline cursor-pointer">
                    INTRAVENOUS THROMBOLYSIS (IVT)
                </span> or endovascular thrombectomy (EVT). Intravenous thrombolysis aims to
                        pharmacologically dissolve blood clots while thrombectomy mechanically extracts them through
                        surgical intervention. In some patients, a combination of both can be used in succession.
                    </div>
                </div>
                <div className="flex flex-col gap-6">
                    <h3 className="font-bold text-primary-red text-[2.4rem] leading-[160%] uppercase font-syne">
                        Current standard-of-care therapies for stroke are limited
                    </h3>
                    <div>
                        Despite its approval several decades ago, intravenous thrombolysis remains the only
                        pharmacological
                        approach approved by regulatory authorities for the acute treatment of stroke. Thrombolysis
                        is
                        primarily undertaken with rtPA, which is associated with numerous limitations including:
                    </div>
                    <ol className="list-decimal list-inside px-2">
                        <li>Time-restricted administration, within 4.5 hours of stroke onset</li>
                        <li>Relatively high rate of failed thrombolysis (especially in large arteries - <span
                            className="font-bold">Large Vessel
                            Occlusions or LVOs</span> - where recanalisation rates can be 20%
                        </li>
                        <li>High rates of rethrombosis in patients with atherosclerotic disease (up to 50%)</li>
                        <li>6-8% risk of symptomatic intracerebral haemorrhage (ICH)</li>
                    </ol>
                    <div>
                        These limitations have led to strict eligibility criteria, wherein rtPA is only administered
                        to
                        10-13% of all stroke patients, and less than half of these achieve a good therapeutic
                        outcome.
                        Of
                        further concern, an even worse prognosis faces stroke patients living in remote and regional
                        areas.
                    </div>
                    <div>
                        The recent introduction of thrombectomy has had a major positive impact on stroke outcomes
                        in
                        patients, particularly those with Large Vessel Occlusions (LVOs), who have
                        historically
                        had
                        the lowest rates of successful reperfusion with thrombolysis alone. Although highly
                        efficacious,
                        thrombectomy is time-consuming, expensive, and can only be performed in major tertiary
                        stroke
                        centres, limiting its availability to stroke patients in regional and remove communities
                    </div>
                    <div className="flex justify-center text-primary-red text-[2rem]">
                        rtPA remains the mainstay of stroke treatment globally
                    </div>
                </div>
                <div className="flex flex-col gap-6">
                    <h2 className="font-syne text-[3.2rem] font-bold leading-[160%] tracking-[.05rem] uppercase">Large
                        Vessel Occlusion (LVO) Stroke</h2>
                    <div>
                        The size of the brain region affected during stroke is largely dependent on the size of the
                        affected
                        blood vessel. <span className="font-bold">Large vessel occlusion strokes (LVOs)</span> occur
                        when there is an interruption of
                        blood
                        flow to one of the main arteries in the brain, with one of the most common large vessels
                        being
                        the
                        Middle Cerebral Artery (MCA).
                    </div>
                </div>
                <div className="flex max-xl:flex-col justify-between items-center max-xl:gap-8">
                    <div className="flex flex-col gap-6">
                        <div>
                            When compared to strokes occurring in the smaller vessels, LVOs have different clinical
                            outcomes
                            and often require different treatment strategies.
                        </div>
                        <div>
                            Because large arteries in the brain supply a substantial area of cerebral tissue, LVOs
                            can
                            cause
                            serious and potentially permanent neurological impairment, affecting a stroke victim’s
                            physical
                            and mental abilities. They also tend to cause swelling in the brain (or oedema), which
                            exacerbates brain injury. As a result, recovery is generally slow, and rehabilitation is
                            almost
                            always a necessity.
                        </div>
                        <div>
                            LVO stroke in the setting of “tandem” carotid artery disease (CAD, development of
                            atherosclerotic plaque in diseased arteries - see FIG) otherwise referred to as <span
                            className="font-bold">Tandem
                            occlusions (TO)</span>, are present in up to 1-in-3 of all patients with stroke undergoing
                            EVT.
                            TOs
                            are
                            considered one of the most challenging stroke patient subpopulations to treat, with high
                            complication rates, and are associated with worse clinical outcomes (high rates of
                            disability
                            and death).
                        </div>
                    </div>
                    <img src="/lvoDiagram.jpg" alt="LVO Diagram" width={600}
                         className="w-[600px]"/>
                </div>
                <div className="flex flex-col gap-6">
                    <div className="font-bold">Current standard-of-care for LVO/TO</div>
                    <div>
                        rtPA is inadequate in situations where there is LVO of intracranial vessels and is
                        demonstrated
                        to
                        be
                        less effective in patients with TOs. For this population of patients with stroke undergoing
                        mechanical
                        clot retrieval (EVT), treatment decisions are often complex, and strategies vary according
                        to
                        clinical,
                        anatomic, and technical considerations in addition to physician preferences.
                    </div>
                    <div>
                        With the growing use of EVT in stroke management, stenting represents a major opportunity to
                        prevent
                        rethrombosis in diseased (atherosclerotic) arteries. Stenting is a unique problem for the
                        patient
                        and a hazardous dilemma for the surgeon. Antithrombotic combinations administered to
                        maintain
                        stent
                        patency (aspirin combined with clopidogrel or ticagrelor) are widely used in the coronary
                        circulation and are highly effective. However in stroke, this approach is manifestly
                        inadequate.
                    </div>
                    <div>
                        Combination of antithrombotic (anti-platelet) therapies in stroke promote disabling brain
                        haemorrhage (6-fold increased risk of haemorrhage with GPIIb-IIIa inhibitors) and fail to
                        prevent
                        acute stent re-thrombosis in more than half of patients (~56%). Safer antithrombotic agents
                        for
                        use
                        with stenting for intracranial cerebral atherosclerosis is desperately needed.
                    </div>
                </div>
                <div className="flex flex-col gap-10">
                    <h2 className="font-syne text-[3.2rem] font-bold leading-[160%] tracking-[.05rem] uppercase">CLINICAL
                        TRIALS</h2>
                    <div className="font-syne text-primary-red text-[2.2rem] leading-[160%] tracking-[0.03rem]">
                        Improving stroke therapy represents an urgent unmet clinical need:
                    </div>
                    <div>
                        Existing stroke therapies are far more optimal. Most patients are either unable to access
                        these
                        life
                        saving therapies or fail to achieve maximal benefit of treatment. Failure of these therapies
                        is
                        due
                        primarily to an inability to adequately dissolve the clot, as well as recurrence of the clot
                        at
                        either the site of vessel damage, or inside an arterial stent.
                        <br/><br/>
                        The addition of other anti-clotting drugs (adjunct anti-thrombotic therapies) is
                        successfully
                        useful
                        in patients with heart attacks, where blood clots are removed from coronary vessels.
                        However,
                        when
                        used in combinations with rtPA to treat stroke, these anti-clotting drugs are associated
                        with
                        high
                        rates of bleeding in the brain even when blocked arteries are reopened.
                    </div>
                    <div className="grid xl:grid-cols-2 gap-10">
                        <div className="border border-black rounded-2xl p-5 flex flex-col gap-6">
                            <div className="text-center">The Problem</div>
                            <div>
                                Antithrombotic drugs such as aspirin and heparin, administered as adjunct therapy to
                                thrombolysis and/or thrombectomy, are routinely used in the coronary circulation to
                                remove
                                pathological blood clots.
                                <br/><br/>
                                However, their acute use in stroke is currently contraindicated, due to unacceptably
                                high
                                rates of intracerebral bleeding.
                            </div>
                        </div>
                        <div className="border border-black rounded-2xl p-5 flex flex-col gap-6">
                            <div className="text-center">The Solution</div>
                            <div>
                                Advances in adjunctive antithrombotic therapies that can safely improve thrombolysis
                                and
                                prevent rethrombosis, would likely have a profound impact on the hyperacute
                                management
                                of
                                stroke.
                                <br/><br/>
                                TBO-309 is currently being trialled in stroke patients in Phase II clinical trials.
                            </div>
                        </div>
                    </div>
                    <img src="/Clinical_Trial_Statistics_for_ThromBio-opt2 Large.jpeg" alt="Trials Graphic"/>
                    <div className="flex xl:justify-center items-center flex-grow">
                        <ul className="font-syne text-[2rem] capitalize">
                            <li>Clinical Trials ID: NCT05363397</li>
                            <li>Sponsor: The George Institute</li>
                            <li>Collaborator: Heart Research Institute</li>
                            <li>Recruiting: October 2023 - Ongoing</li>
                        </ul>
                    </div>
                </div>
                <div className="flex flex-col gap-6">
                    <h3 className="capitalize font-syne text-[3.2rem] text-primary-red tracking-[.05rem]">Brief
                        Summary</h3>
                    <div className="text-center">
                        STARS is a prospective, multi centre, open-label, dose escalation, Phase IIa study to assess
                        the
                        safety and tolerability of TBO-309, an adjuvant antiplatelet therapy, in patients with Acute
                        ischaemic stroke (AIS). AIS is caused by a severe blockage of an artery leading to immediate
                        reduced
                        blood flow to part of the brain. Standard therapies target the blocked artery by either
                        dissolving
                        the blockage or removing the blockage. However, even after the successful treatment,
                        re-blockage
                        of
                        arteries can occur. The use of an antiplatelet therapy, TBO-309, in addition to standard
                        therapies,
                        offers the possibility of improved restoration of blood flow and reduced rates of artery
                        re-blockage.
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-16 items-center">
                    <div className="max-xl:col-span-4 max-xl:justify-center flex"><img src="/starsLogo.jpg"
                                                                                       alt="Stars Logo"/></div>
                    <div className="col-span-4 xl:col-span-3 grid md:grid-cols-3 gap-8">
                        <div className="flex flex-col items-center gap-6">
                            <img src="/royalMelbournHospital.jpg" alt="Royal Melbourne Hospital"/>
                            <div className="text-center">
                                Principal Investigator
                                <br/>
                                Professor Bruce Campbell
                            </div>
                        </div>
                        <div className="flex flex-col items-center gap-6">
                            <img src="/johnHunterHospital.jpg" alt="John Hunter Hospital"/>
                            <div className="text-center">
                                Principal Investigator
                                <br/>
                                Professor Chris Levi
                            </div>
                        </div>
                        <div className="flex flex-col items-center gap-6">
                            <img src="/royalAdelaideHospital.jpg" alt="Royal Adelaide Hospital"/>
                            <div className="text-center">
                                Principal Investigator
                                <br/>
                                Professor Tim Kleinig
                            </div>
                        </div>
                        <div className="flex flex-col items-center gap-6">
                            <img src="/liverpoolHospital.jpg" alt="Liverpool Hospital"/>
                            <div className="text-center">
                                Principal Investigator
                                <br/>
                                Professor Mark Parsons
                                <br/>
                                Dr. Chris Blair
                            </div>
                        </div>
                        <div className="flex flex-col items-center gap-6">
                            <img src="/princeOfWalesHospital.jpg" alt="The Prince Of Wales Hospital"/>
                            <div className="text-center">
                                Principal Investigator
                                <br/>
                                Professor Ken Butcher
                            </div>
                        </div>
                        <div className="flex flex-col items-center gap-6">
                            <img src="/royalPrinceAlfredHospital.jpg" alt="Royal Prince Alfred Hospital"/>
                            <div className="text-center">
                                Principal Investigator
                                <br/>
                                Dr. Tim Ang
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}