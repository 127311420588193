import {Link, useLocation} from "react-router-dom";

export default function Footer() {
  const location = useLocation().pathname;

    return (
        <div className={`bg-primary max-w-dvw z-10 ${location === "/" ? "mt-16 lg:mt-40" : ""}`}>
      <div className="page-width flex justify-end py-10 md:py-16">
        <Link to="/"><img src="/thromBioInvert.png" alt="ThromBio Logo" className="max-lg:max-h-[40px]" /></Link>
      </div>
    </div>
  );
}
