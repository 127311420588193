import SlideIn from "./SlideIn";

export default function LeadershipProfile(props) {
  const { img, name, position, children, noBorder } = props;
  return (
    <SlideIn>
      <div
        className={`flex gap-10 max-md:flex-col pb-12 md:pb-16 lg:pb-20 ${noBorder ? "" : "border-b border-gray-200"}`}
      >
        <div className="min-w-[150px] max-w-[150px]">
          <img src={img} alt={name} />
        </div>
        <div className="flex gap-6 md:gap-10 justify-between flex-grow max-xl:flex-col">
          <div className="flex flex-col gap-3 flex-grow">
            <div className="subheading text-secondary-skyBlue font-bold tracking-tight">{name}</div>
            <div className="text-secondary-skyBlue font-bold">{position}</div>
          </div>
          <div className="max-w-prose">{children}</div>
        </div>
      </div>
    </SlideIn>
  );
}
