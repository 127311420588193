import { Route, Routes } from "react-router-dom";
import Leadership from "./pages/Leadership";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Candidate from "./pages/technology/Candidate";
import Home from "./pages/Home";
import About from "./pages/About";
import Publications from "./pages/Publications";
import Contact from "./pages/Contact";
import LeadIndications from "./pages/technology/LeadIndications";
import Media from "./pages/Media";

export default function App() {
  return (
    <div className="min-h-dvh flex flex-col">
      <Header />
      <div className="flex-grow relative">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/leadership/*" element={<Leadership />} />
          <Route path="/media/*" element={<Media />} />
          <Route path="/publications/*" element={<Publications />} />
          <Route path="/technology/candidate" element={<Candidate />} />
          <Route path="/technology/leadIndication" element={<LeadIndications />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}
