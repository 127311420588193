import {useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";

export default function Header() {

    const location = useLocation().pathname


    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const [isTechnologyAccordionOpen, setIsTechnologyAccordionOpen] = useState(false);
    const [isTechnologyDropdownOpen, setIsTechnologyDropdownOpen] = useState(false);
    const techologyDropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (techologyDropdownRef.current && !techologyDropdownRef.current.contains(event.target)) {
            setIsTechnologyDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className={`${location === '/' ? "bg-primary" : "bg-white shadow"} text-[1.8rem] font-medium max-w-dvw sticky top-0 left-0 z-10`}>
            <div
                className={`max-lg:px-10 page-width flex flex-nowrap max-lg:justify-between gap-32 xl:gap-40 items-center min-h-40 nav ${location === "/" ? "text-white" : "text-primary"}`}>
                <Link to="/">
                    <img src={location === "/" ? "/thromBioInvert.png" : "/thrombioLogo.png"} alt="ThromBio Logo"
                         height={40} className="h-16"/>
                </Link>
                <div className="lg:hidden" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-12 h-12">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                    </svg>
                </div>
                <div className="hidden lg:flex flex-nowrap justify-between flex-grow items-center">
                    <Link className={location.startsWith("/about") ? "font-sans font-bold text-primary-red" : undefined}
                          to="/about">About</Link>
                    <Link
                        className={location.startsWith("/leadership") ? "font-sans font-bold text-primary-red" : undefined}
                        to="/leadership">Leadership</Link>
                    <div className="flex flex-col relative hover:text-primary-red transition-all">
                        <div ref={techologyDropdownRef}
                             onClick={() => setIsTechnologyDropdownOpen(!isTechnologyDropdownOpen)}
                             className="cursor-pointer">
                            <div className="flex gap-2 lg:gap-4 items-center cursor-pointer">
                                <span className={`${location.startsWith("/technology") ? "font-sans font-bold text-primary-red" : undefined}`} >Technology</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5}
                                     stroke="currentColor"
                                     className={`w-8 h-8 transition-all ${isTechnologyDropdownOpen ? "rotate-180" : ""}`}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5"/>
                                </svg>
                            </div>
                            <div
                                className={`-top-4 flex flex-col divide-y justify-between transition-all absolute bg-white shadow py-2 text-primary ${isTechnologyDropdownOpen ? "scale-y-100 translate-y-1/2" : "scale-y-0 opacity-0 translate-y-0"}`}>
                                <div className="flex items-center min-h-16 px-4"><Link to="/technology/candidate"
                                                                                       className={`whitespace-nowrap ${location.startsWith("/technology/candidate") ? "text-primary-red" : ""}`}>Our
                                    Lead Candidate</Link></div>
                                <div className="flex items-center min-h-16 px-4"><Link to="/technology/leadIndication"
                                                                                       className={`whitespace-nowrap ${location.startsWith("/technology/leadIndication") ? "text-primary-red" : ""}`}>Lead
                                    Indications</Link></div>
                                {/*<div className="flex items-center min-h-16 px-4"><Link*/}
                                {/*    to="/technology/developmentPipeline" className={`whitespace-nowrap ${location.startsWith("/technology/developmentPipeline") ? "text-primary-red" : ""}`}>Our*/}
                                {/*    Development Pipeline</Link></div>*/}
                            </div>
                        </div>
                    </div>
                    {/*<Link className={location.startsWith("/technology") && "font-sans font-bold text-primary-red"}*/}
                    {/*      to="/technology/candidate">Technology</Link>*/}
                    <Link
                        className={location.startsWith("/publications") ? "font-sans font-bold text-primary-red" : undefined}
                        to="/publications">Publications</Link>
                    <Link className={location.startsWith("/media") ? "font-sans font-bold text-primary-red" : undefined}
                          to="/media">Media</Link>
                    <Link
                        className={location.startsWith("/contact") ? "font-sans font-bold text-primary-red" : undefined}
                        to="/contact">Contact</Link>
                </div>
            </div>
            <div
                className={`${location === '/' ? "bg-primary text-white" : "bg-white shadow-inner"} ${isMobileMenuOpen ? "" : "scale-y-0 -transalate-y-1/2"} transition-all max-w-dvw sticky top-0 left-0 z-10 flex flex-col nav mobileNav`}>
                <div
                    className={`${location === '/' ? "bg-primary text-white border-red" : "bg-white"} absolute w-full flex flex-col nav mobileNav max-lg:px-10 border-b`}>
                    <Link onClick={() => setIsMobileMenuOpen(false)} className={location.startsWith("/about") ? "font-sans font-bold text-primary-red" : undefined}
                          to="/about">About</Link>
                    <Link onClick={() => setIsMobileMenuOpen(false)}
                        className={location.startsWith("/leadership") ? "font-sans font-bold text-primary-red" : undefined}
                        to="/leadership">Leadership</Link>
                    <div className="flex flex-col relative mobileNavAccordion">
                        <div
                            onClick={() => setIsTechnologyAccordionOpen(!isTechnologyAccordionOpen)}
                            className="cursor-pointer">
                            <div className="flex gap-2 lg:gap-4 items-center cursor-pointer">
                                <span
                                    className={`${location.startsWith("/technology") ? "font-sans font-bold text-primary-red" : undefined}`} >Technology</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5}
                                     stroke="currentColor"
                                     className={`w-8 h-8 transition-all ${isTechnologyAccordionOpen ? "rotate-180" : ""}`}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5"/>
                                </svg>
                            </div>
                            <div
                                className={`flex flex-col divide-y justify-between transition-all py-2 ${isTechnologyAccordionOpen ? "scale-y-100" : "scale-y-0 -translate-y-1/2 max-h-0 opacity-0"}`}>
                                <div className={`${location.includes("candidate") ? "text-primary-red" : ""} flex items-center min-h-16 px-4`}><Link to="/technology/candidate" onClick={() => setIsMobileMenuOpen(false)}
                                                                                       className={`whitespace-nowrap ${location.startsWith("/technology/candidate") ? "text-primary-red" : ""}`}>Our
                                    Leading Candidate</Link></div>
                                <div className={`${location.includes("leadIndication") ? "text-primary-red" : ""} flex items-center min-h-16 px-4`}><Link to="/technology/leadIndication" onClick={() => setIsMobileMenuOpen(false)}
                                                                                       className="whitespace-nowrap">Lead
                                    Indications</Link></div>
                                {/*<div className={`${location.includes("developmentPipeline") ? "text-primary-red" : ""} flex items-center min-h-16 px-4`}><Link onClick={() => setIsMobileMenuOpen(false)}*/}
                                {/*    to="/technology/developmentPipeline" className="whitespace-nowrap">Our*/}
                                {/*    Development Pipeline</Link></div>*/}
                            </div>
                        </div>
                    </div>
                    <Link onClick={() => setIsMobileMenuOpen(false)}
                        className={location.startsWith("/publications") ? "font-sans font-bold text-primary-red" : undefined}
                        to="/publications">Publications</Link>
                    <Link className={location.startsWith("/media") ? "font-sans font-bold text-primary-red" : undefined} onClick={() => setIsMobileMenuOpen(false)}
                          to="/media">Media</Link>
                    <Link onClick={() => setIsMobileMenuOpen(false)}
                        className={location.startsWith("/contact") ? "font-sans font-bold text-primary-red" : undefined}
                        to="/contact">Contact</Link>
                </div>
            </div>
        </div>
    )
}