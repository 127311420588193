export default function Candidate() {
    return (
        <div className="pageContainer relative">
            <div className="fixed w-full h-2/3 bg-contain opacity-30 bg-no-repeat left-1/2 z-[-1]"
                 style={{backgroundImage: "url('/Line_gradient_touch_ups_(3).jpeg')"}}></div>
            <h1 className="font-bold">Our Lead Candidate: TBO-309</h1>
            {/*<p>TBO-309 (aka AZD6482, TGX-221) is a potent and selective small molecule inhibitor of a ubiquitous enzyme*/}
            {/*    known as Phosphoinositide 3-kinase (PI3-kinase, PI3K). More specifically,TBO-309 is selective for the*/}
            {/*    Type Ia p110&beta; isoform of PI3K (PI3K&beta;), with an IC50 of 0.69 nM.</p>*/}
            <div className="flex flex-col gap-10">
                <div className="text-red-600 font-syne uppercase text-[2rem]"><span className="normal-case">PI<span
                    className="relative -top-1">3</span>Kβ</span> is a critical
                    regulator of pathological blood clotting (Thrombosis)
                </div>
                <p>
                    TBO-309 represents an Australian drug development success story, that has resulted in a novel
                    antiplatelet compound with truly remarkable properties. A key point of differentiation between
                    TBO-309 and currently approved antiplatelets is its wide therapeutic window. When combined with
                    existing anti-clotting therapies, it does not cause a major increase in bleeding. This has been
                    confirmed in several proof-of-concept studies in both animal models and two Phase I human clinical
                    trials.
                </p>
                <ul className="list-disc list-outside ml-6">
                    <li>Professor Jackson and his team discovered that PI3Kβ was a critical regulator of platelet
                        function and thrombosis (Jackson et al, Nature Medicine, 2005)
                    </li>
                    <li>Pharmacological inhibitors against PI3Kβ identified a critical role for this enzyme in promoting
                        shear-dependent platelet activation (Nesbitt et al, 2009, Nature Med)
                    </li>
                    <li><span>In a broad range of preclinical animal models, and in two Phase I human clinical trials
                        (1-3), studies have demonstrated:</span>
                        <div>
                            <ul className="list-inside ml-6" style={{listStyleType: "circle"}}>
                                <li>that TBO-309 (AZD6482) can inhibit arterial thrombus formation in a dose-dependent
                                    manner, without causing a major increase in bleeding
                                </li>
                                <li>that TBO-309 can be safely and effectively combined with other antithrombotic agents without causing a major increase in bleeding</li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="flex flex-col gap-6">
                <div className="font-syne text-[2.4rem] uppercase font-bold">Efficacy and Safety of TBO-309</div>
                <p>
                    Since its identification as an important antithrombotic target, inhibitors of PI3K&beta; have been
                    further characterised and developed. TBO-309 (aka AZD6482) has been taken through two Phase I
                    clinical trials (Astra Zeneca), with reports demonstrating an excellent safety profile in healthy
                    human male subjects (1,2).
                </p>
                <p>
                    Additional Phase I studies have also demonstrated safety and eﬃcacy in combination with other
                    antiplatelet and anticoagulant therapies (3).
                </p>
                <div className="flex max-md:flex-col gap-6 items-center">
                    <div className="flex-shrink">
                        <div><span className="text-secondary-maroon">1. DOI :</span> <a
                            href="http://doi.org/10.1111/j.1538-7836.2012.04898.x"
                            target="_blank" rel="noreferrer">http://doi.org/10.1111/j.1538-7836.2012.04898.x</a></div>
                        <ul className="list-disc list-inside">
                            <li>Antithrombotic phosphoinositide 3-kinase β inhibitors in humans: a 'shear' delight!</li>
                        </ul>
                        <div><span className="text-secondary-maroon">2. DOI :</span> <a
                            href="http://doi.org/10.1111/j.1538-7836.2012.04912.x"
                            target="_blank" rel="noreferrer">http://doi.org/10.1111/j.1538-7836.2012.04912.x</a></div>
                        <ul className="list-disc list-inside">
                            <li>Exploration of efficacy and bleeding with combined
                                phosphoinositide 3-kinase β inhibition and aspirin in man
                            </li>
                        </ul>
                        <div><span className="text-secondary-maroon">3. DOI :</span> <a
                            href="http://doi.org/10.1111/jth.13027" target="_blank"
                            rel="noreferrer">http://doi.org/10.1111/jth.13027</a>
                        </div>
                        <ul className="list-disc list-inside">
                            <li>Human target validation of phosphoinositide 3-kinase (PI3K)β: effects on platelets and
                                insulin sensitivity, using AZD6482 a novel PI3Kβ inhibitor
                            </li>
                        </ul>
                    </div>
                    <div className="flex-grow w-full">
                        <img src="/tbo-309.jpeg" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}