import {useEffect, useRef, useState} from "react";

export default function Publications() {
    return (
        <div className="pageContainer">
            <div className="flex flex-col gap-10">
                <h1 className="font-bold h3">publications</h1>
                <h3 className="font-bold h1">review articles</h3>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
                <PublicationCard link="https://doi.org/10.1182/blood-2018-11-882993" imgSrc="/thrombioInflammation.png"
                                 pubYear={2019}>
                    <p>Thromboinflammation: challenges of therapeutically targeting coagulation and other host
                        defense
                        mechanisms.</p>
                    <p>Jackson et a, 2019.</p>
                </PublicationCard>
                <PublicationCard link="https://doi.org/10.1038/nm.2515" imgSrc="/arterialThrombosis.png" pubYear={2011}>
                    <p>Arterial thrombosis - insidious, unpredictable and deadly.
                    </p>
                    <p>Jackson, 2011.</p>
                </PublicationCard>
                <PublicationCard link="https://doi.org/10.1007/82_2010_61" imgSrc="/ra3.png" pubYear={2011}>
                    <p>PI 3-Kinase p110β regulation of platelet integrin α(IIb)β3.
                    </p>
                    <p>Jackson and Schoenwaelder, 2010.</p>
                </PublicationCard>
                <PublicationCard link="https://doi.org/10.1038/nrd1198" imgSrc="/ra4.png" pubYear={2003}>
                    <p>Antiplatelet therapy: in search of the 'magic bullet'.
                    </p>
                    <p>Jackson and Schoenwaelder, 2003.</p>
                </PublicationCard>
                <PublicationCard link="https://doi.org/10.1007/s00018-006-6001-2" imgSrc="/ra5.png" pubYear={2006}>
                    <p>Type I phosphoinositide 3-kinases: potential antithrombotic targets?
                    </p>
                    <p>Jackson and Schoenwaelder, 2006.</p>
                </PublicationCard>
            </div>
            <h3 className="font-bold h1">primary research articles</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-10">

                <PublicationCard link="https://doi.org/10.1038/s41563-019-0323-6" imgSrc="/pr9.png" pubYear={2019}>
                    <p>An integrin α IIb β 3 intermediate aﬃnity state mediates biomechanical platelet aggregation.
                    </p>
                    <p>Chen <i>et al</i>, 2019.</p>
                </PublicationCard>
                <PublicationCard link="https://doi.org/10.1038/s41467-018-03430-6" imgSrc="/pr8.png" pubYear={2018}>
                    <p>Compression force sensing regulates integrin αIIbβ3 adhesive function on diabetic platelets.
                    </p>
                    <p>Ju <i>et al</i>, 2018.</p>
                </PublicationCard>
                <PublicationCard link="https://doi.org/10.1182/blood-2017-06-789032" imgSrc="/pr7.png" pubYear={2017}>
                    <p>Endogenous fibrinolysis facilitates clot retraction in vivo.
                    </p>
                    <p>Samson <i>et al</i>, 2017.</p>
                </PublicationCard>
                <PublicationCard link="https://doi.org/10.1074/jbc.M109.029132" imgSrc="/pr6.png" pubYear={2010}>
                    <p>Phosphoinositide 3-kinase p110 beta regulates integrin alpha IIb beta 3 avidity and the cellular
                        transmission of contractile forces.
                    </p>
                    <p>Schoenwaelder <i>et al</i>, 2010.</p>
                </PublicationCard>
                <PublicationCard link="https://doi.org/10.1038/nm.1955" imgSrc="/pr5.png" pubYear={2009}>
                    <p>A shear gradient-dependent platelet aggregation mechanism drives thrombus formation.
                    </p>
                    <p>Nesbitt <i>et al</i>, 2009.</p>
                </PublicationCard>
                <PublicationCard link="http://dx.doi.org/10.1074/jbc.M704358200" imgSrc="/pr4.png" pubYear={2007}>
                    <p>Identification of a unique co-operative phosphoinositide 3-kinase signaling mechanism regulating
                        integrin aIIbb3 adhesive function in platelets.
                    </p>
                    <p>Schoenwaelder <i>et al</i>, 2007.</p>
                </PublicationCard>
                <PublicationCard link="http://doi.org/10.1074/jbc.M410235200" imgSrc="/pr3.png" pubYear={2005}>
                    <p>PIImportance of temporal flow gradients and integrin aIIbb3 mechanotransduction for shear
                        activation of platelets.
                    </p>
                    <p>Goncalves <i>et al</i>, 2005.</p>
                </PublicationCard>
                <PublicationCard link="https://doi.org/10.1038/nm1232" imgSrc="/pr2.png" pubYear={2005}>
                    <p>PI 3-kinase p110beta: a new target for antithrombotic therapy.
                    </p>
                    <p>Jackson <i>et al</i>, 2005.</p>
                </PublicationCard>
                <PublicationCard link="https://doi.org/10.1182/blood.V99.1.151" imgSrc="/pr1.png" pubYear={2002}>
                    <p>Essential role for phosphoinositide 3-kinase in shear-dependent signaling between platelet
                        glycoprotein Ib/V/IX and integrin a(IIb)b3.
                    </p>
                    <p>Yap <i>et al</i>, 2002.</p>
                </PublicationCard>
            </div>
        </div>
    )
}

function PublicationCard(props) {
    const {link, imgSrc, children, altText, pubYear} = props;

    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(elementRef.current);
                }
            },
            {threshold: 0.5} // Trigger when 50% of the element is visible
        );

        observer.observe(elementRef.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div ref={elementRef} className={`slide-in ${isVisible ? 'active' : ''} flex flex-col gap-10 items-center`}>
            <a href={link} target="_blank" rel="noreferrer"
               className="relative group !no-underline w-[165px] h-[218px] md:w-[185px] md:h-[250px]">
                <img src={imgSrc} alt={altText} className="absolute left-0 top-0 shadow-xl"/>
                <div
                    className="relative h-full bg-black/70 z-1 text-white flex flex-col justify-between p-4 transition opacity-0 group-hover:opacity-100 -translate-x-1/2 scale-x-0 group-hover:scale-x-100 group-hover:translate-x-0 drop-shadow-sm duration-300">
                    {children}
                </div>
                <img src={imgSrc} alt="" className="invisible"/>
            </a>
            <div className="font-bold">{pubYear}</div>
        </div>
    );
}
